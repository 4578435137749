<template>
  <div class="root-box">
    <div class="query-container">
      <el-form size="small" :inline="true">
        <el-form-item label="路线：" prop="pathId">
          <el-select
            style="width: 160px"
            v-model="param.pathId"
            placeholder="请选择"
            @change="query"
            clearable
          >
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域：" prop="pathId">
          <el-select
            style="width: 160px"
            v-model="param.isStart"
            placeholder="请选择"
            @change="query"
            clearable
          >
            <el-option label="起点" :value="true"></el-option>
            <el-option label="终点" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域类型：" prop="areaType">
          <el-select
            style="width: 200px"
            v-model="param.areaType"
            placeholder="请选择"
            @change="query"
          >
            <el-option
              v-for="item in areaTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label>
          <el-form-item label="地址搜索">
            <input id="tipinput" type="text" />
          </el-form-item>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query"
            >查询</el-button
          >

          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            v-if="isRecommendAddress"
            @click="chcekAddressInScope"
            >检测所属区域</el-button
          >
        </el-form-item>

        <el-form-item label>
          <el-button type="success" icon="el-icon-document-copy" @click="visible2 = true"
            >复制区域</el-button
          >
        </el-form-item>

        <el-form-item v-if="chooseItem && !isRecommendAddress" style="float: right">
          <el-button-group>
            <el-button type="success" icon="el-icon-delete" @click="removeOverlays">删除</el-button>
            <el-button type="success" icon="el-icon-edit" @click="editArea">修改</el-button>
            <el-button type="success" icon="el-icon-refresh-right" @click="clearChoose"
              >清除选择</el-button
            >
            <el-button
              v-if="tempOverlay"
              type="warning"
              icon="el-icon-upload"
              @click="updateOverlay"
              >保存修改</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-form>
    </div>

    <div class="up_price_map">
      <div id="up_price_map"></div>

      <div class="result left" v-if="selectMarkerList.length">
        <div
          v-for="(item, index) in selectMarkerList"
          :key="item.id"
          @click="selectMarker(index, item)"
        >
          <div class="marker-content" :class="{ disable: item.disable }">
            <div class="name">
              <span class="icon" :class="{ active: index === selectMarkerIndex }">
                {{ index + 1 }}
              </span>
              <span>{{ item.name }}</span>
            </div>
            <div class="btn">
              <span @click.stop="delAddress(item, index)">删除</span>

              <span v-if="item.wrong" @click.stop="updateBelong(item, index)">更新</span>
            </div>
          </div>
        </div>
      </div>

      <div class="result" v-if="searchResult.length">
        <div
          v-for="(item, index) in searchResult"
          :key="item.id"
          @click="selectSearchMarker(index, item)"
        >
          <div class="marker-content" :class="{ disable: item.disable }">
            <div class="name">
              <span class="icon" :class="{ active: index === selectSearchMarkerIndex }">
                {{ index + 1 }}
              </span>
              <span>{{ item.name }}</span>
            </div>
            <div class="btn" @click="chooseAddress(index, item)">选择</div>
          </div>
        </div>
      </div>

      <div v-if="chooseInfo" class="uprice-info">
        <div>名称：{{ chooseInfo.name }}</div>
        <div>加价：{{ chooseInfo.price }}</div>
      </div>

      <div class="input-card">
        <div class="input-item">
          <el-radio-group v-model="radio">
            <el-radio :label="SHAPE.polygon">画多边形</el-radio>
            <el-radio :label="SHAPE.none">不选择</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="visible"
      width="500px"
      max-height="600"
      title="区域设置"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="120px" :size="size" :rules="rules">
        <el-form-item v-if="isUpriceArea || isSpecArea" label="区域名称：" prop="name">
          <el-input v-model="form.name" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item v-if="isUpriceArea" label="加价金额：" prop="price">
          <el-input-number v-model="form.price" style="width: 100%"></el-input-number>
        </el-form-item>

        <el-form-item v-if="isUpriceArea || isSpecArea" label="备注说明：" prop="remark">
          <el-input v-model="form.remark" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item label="路线：" prop="pathId">
          <el-select disabled v-model="form.pathId" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域类型：" prop="areaType">
          <el-select disabled v-model="form.areaType" style="width: 100%">
            <el-option
              v-for="item in areaTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.areaType === AREA_TYPE.CITY_SCOPE"
          label="完全顺路区域："
          prop="isOnWayArea"
        >
          <el-select v-model="form.isOnWayArea" style="width: 100%">
            <el-option
              v-for="item in yesNoOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.areaType === AREA_TYPE.SPEC_AREA"
          label="途经点经度："
          prop="latitude"
        >
          <el-input v-model="form.latitude" style="width: 100%"></el-input>
        </el-form-item>

        <el-form-item
          v-if="form.areaType === AREA_TYPE.SPEC_AREA"
          label="途经点纬度："
          prop="longitude"
        >
          <el-input v-model="form.longitude" style="width: 100%"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="save">保存</el-button>
        <el-button type="info" :size="size" @click="cancel">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="visible2"
      width="500px"
      max-height="600"
      title="复制区域"
      :close-on-click-modal="false"
    >
      <el-form size="small" :inline="true" label-width="120px">
        <el-form-item label="复制路线：" prop="pathId">
          <el-select v-model="copyParam.pathId" style="width: 100%">
            <el-option
              v-for="item in pathOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域：" prop="isStart">
          <el-select v-model="copyParam.isStart" placeholder="请选择">
            <el-option label="起点" :value="true"></el-option>
            <el-option label="终点" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="复制类型：" prop="areaType">
          <el-select v-model="copyParam.areaType" placeholder="请选择">
            <el-option
              v-for="item in areaTypeOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="warning" :size="size" @click="copyArea">确定</el-button>
        <el-button type="info" :size="size" @click="visible2 = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listUpriceArea,
  addUpriceArea,
  modifyUpriceArea,
  removeUpriceArea,
  areaPrice,
  copyUpriceArea,
} from "@/api";
import { SHOW_LOCATION_SELECT_MAP } from "@/core/const/global-event";
import { MapUtil } from "@/util";
import { BaseDataService } from "@/service";
import { mapState } from "vuex";
import config from "@/config/config";
import _ from "lodash";
const markB = require("../../../assets/image/mark_b.png");

const SHAPE = {
  polygon: "3",
  none: "none",
};

const AREA_TYPE = {
  UPRICE: 1,
  CITY_SCOPE: 2,
  STANDARD_AREA: 3,
  RECOMMEND_ADDRESS: 4,
  EXPRESSWAY_AREA: 5,
  SPEC_AREA: 10,
  SPEC_AREA2: 100,
};

export default {
  mounted() {
    this.initMap();
    this.draw(this.radio);
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      areaTypeOpts: (state) => state.enumMap["AREA_TYPE"],
      pathOpts: (state) => {
        const pathList = state.pathList;
        return pathList
          .filter((item) => item.isStartPath)
          .map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
      },
    }),
    isUpriceArea() {
      return this.param && this.param.areaType === AREA_TYPE.UPRICE;
    },
    isSpecArea() {
      return this.param && this.param.areaType === AREA_TYPE.SPEC_AREA;
    },
    isRecommendAddress() {
      return this.param && this.param.areaType === AREA_TYPE.RECOMMEND_ADDRESS;
    },

    selectMarkerIndex() {
      if (this.selectMarkerObj) {
        return this.selectMarkerObj.getExtData().index;
      }
      return 0;
    },
    selectSearchMarkerIndex() {
      if (this.selectSearchMarkerObj) {
        return this.selectSearchMarkerObj.getExtData().index;
      }
      return 0;
    },

    chooseInfo() {
      if (this.priceItem) {
        const data = this.priceItem.getExtData();
        return data.originData;
      }
      return "";
    },
  },
  watch: {
    radio(val) {
      this.draw(val);
    },
  },
  data() {
    return {
      SHAPE,
      AREA_TYPE,
      yesNoOpts: BaseDataService.yesNoOpts(),
      param: {
        areaType: 1,
        isStart: true,
        pathId: config.initPath,
      },
      radio: SHAPE.none,
      visible: false,
      visible2: false,
      drawerVisible: false,
      form: {},
      rules: {
        price: [
          {
            required: true,
            message: "请输入加价金额",
            trigger: "blur",
          },
        ],
      },
      list: [],
      copyParam: {},
      chooseItem: null,
      tempOverlay: null,
      searchResult: [],
      selectSearchMarkerObj: null,
      selectMarkerList: [],
      selectMarkerObj: null,
      priceItem: null,
      upStyle: {},
    };
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("up_price_map", {
        resizeEnable: true,
        dragEnable: true,
        zoom: 14,
      });

      // 绘制矢量图形：https://lbs.amap.com/api/javascript-api/guide/overlays/editable-vector-overlay/?sug_index=0
      AMap.plugin(
        [
          "AMap.MouseTool",
          "AMap.PolygonEditor",
          "AMap.PlaceSearch",
          "AMap.AutoComplete",
          "AMap.Scale",
        ],
        () => {
          const mouseTool = new AMap.MouseTool(this.map);

          const scale = new AMap.Scale();
          this.map.addControl(scale);

          this.mouseTool = mouseTool;
          //监听draw事件可获取画好的覆盖物
          mouseTool.on("draw", (e) => {
            if (this.param.pathId) {
              setTimeout(() => {
                if (this.isRecommendAddress) {
                  // 排除无效的点击
                  if (e.obj.getArea() > 0) {
                    this.searchInBounds(e.obj);
                  }
                } else {
                  this.openForm(e.obj);
                }
              }, 100);
            }
          });

          const autoOptions = {
            // input 为绑定输入提示功能的input的DOM ID
            input: "tipinput",
            city: "全国",
          };
          const autoComplete = new AMap.AutoComplete(autoOptions);
          autoComplete.on("select", this.select);

          const placeSearch = new AMap.PlaceSearch({
            pageSize: 100,
            pageIndex: 1,
            autoFitView: true,
          });
          this.placeSearch = placeSearch;
        }
      );
    },

    rightclick(info) {
      setTimeout(() => {
        const overlay = this.tempOverlay;
        const area = overlay.getPath().map((item) => {
          return {
            x: item.lat,
            y: item.lng,
          };
        });

        const param = {
          point: {
            x: info.lnglat.lat,
            y: info.lnglat.lng,
          },
          area,
        };
        // AMap.GeometryUtil.isPointInRing([info.lnglat.lng, info.lnglat.lat], [[112.75982658618837,30.049945319603907],[112.76010650614153,30.032807427789397]]);

        areaPrice(param).then((res) => {
          this.$message.info(`${res.data}`);
        });
      }, 100);
    },

    searchInBounds(overlay) {
      const map = this.map;
      // 删除之前的区域
      if (this.overlay) {
        map.remove(this.overlay);
      }
      if (this.markers) {
        map.remove(this.markers);
      }
      this.overlay = overlay;

      const keyWords = document.getElementById("tipinput").value || "公交站";
      this.placeSearch.searchInBounds(keyWords, overlay, (status, result) => {
        if (status === "complete") {
          const list = result.poiList.pois || [];

          this.selectSearchMarkerObj = null;

          const selectedMarkerKeys = this.selectedMarkerKeys || [];

          const _this = this;
          const markers = [];
          const resultList = [];
          let index = 0;
          for (let i = 0; i < list.length; i++) {
            const item = list[i];
            const { lng, lat } = item.location || {};

            const key = `${lng}_${lat}`;

            // 排除已经选择过的地址
            if (selectedMarkerKeys.indexOf(key) >= 0) {
              continue;
            }

            resultList.push(item);

            const extData = {
              index,
              lat,
              lng,
              name: item.name,
            };

            const marker = new AMap.Marker({
              position: [lng, lat],
              title: item.name,
              clickable: true,
              content: `<div class="amap_lib_placeSearch_poi">${index + 1}</div>`,
              extData,
            });
            marker.on("click", () => {
              const data = marker.getExtData();
              if (data.disable) {
                return;
              }
              this.clearLastSelectMarker();
              marker.setContent(
                `<div class="amap_lib_placeSearch_poi active">${data.index + 1}</div>`
              );
              _this.selectSearchMarkerObj = marker;
            });

            markers.push(marker);
            index++;
          }

          this.markers = markers;
          map.add(markers);
          map.setFitView(markers);

          this.searchResult = resultList;
        } else {
          this.searchResult = [];
        }

        this.mouseTool.close(true);
        this.radio = SHAPE.none;
      });
    },

    selectSearchMarker(index, item) {
      if (item.disable) {
        return;
      }
      this.clearLastSelectMarker();
      this.selectSearchMarkerObj = this.markers[index];
      this.selectSearchMarkerObj.setContent(
        `<div class="amap_lib_placeSearch_poi active">${index + 1}</div>`
      );
    },

    clearLastSelectMarker(type = 1) {
      // type 1: 当前搜索出的地址  2：已经选择过的地址
      if (type == 1 && this.selectSearchMarkerObj) {
        const old = this.selectSearchMarkerObj.getExtData();
        this.selectSearchMarkerObj.setContent(
          `<div class="amap_lib_placeSearch_poi">${old.index + 1}</div>`
        );
      } else if (type == 2 && this.selectMarkerObj) {
        const old = this.selectMarkerObj.getExtData();
        this.selectMarkerObj.setContent(
          `<div class="amap_lib_placeSearch_poi yellow">${old.index + 1}</div>`
        );
      }
    },

    clearAddressSelect() {
      this.searchResult = [];
      this.selectSearchMarkerObj = null;
      this.selectMarkerList = [];
      this.selectMarkerObj = null;
    },

    chooseAddress(index, item) {
      if (item.disable) {
        return;
      }

      const data = this.markers[index].getExtData();

      let belongArea;
      for (let index = 0; index < this.overlays.length; index++) {
        const element = this.overlays[index];
        const isInArea = AMap.GeometryUtil.isPointInRing([data.lng, data.lat], element.getPath());
        if (isInArea) {
          belongArea = element.getExtData().yxId;
          break;
        }
      }

      if (!belongArea) {
        this.$message.error("所选的点不在不加价区域内");
        return;
      }

      const param = {
        ...this.param,
        name: data.name,
        longitude: data.lng,
        latitude: data.lat,
        belongArea,
      };

      addUpriceArea(param).then(() => {
        this.selectSearchMarkerObj.setContent(
          `<div class="amap_lib_placeSearch_poi yellow">${index + 1}</div>`
        );

        item.disable = true;
        data.disable = true;
        this.selectSearchMarkerObj.setExtData(item);
        this.selectSearchMarkerObj = null;
        this.$set(this.searchResult, index, item);
        this.$message.success("地点保存成功");
      });
    },

    delAddress(item, index) {
      if (item.disable) {
        return;
      }

      const param = { id: item.id };

      this.$confirm("确认删除该地址吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUpriceArea(param).then(() => {
          const marker = this.selectMarkers[index];
          this.map.remove(marker);
          this.$message.success("地址删除成功");
          item.disable = true;
          this.$set(this.selectMarkerList, index, item);
        });
      });
    },

    selectMarker(index, item) {
      if (item.disable) {
        return;
      }
      this.clearLastSelectMarker(2);
      this.selectMarkerObj = this.selectMarkers[index];
      this.selectMarkerObj.setContent(
        `<div class="amap_lib_placeSearch_poi active">${index + 1}</div>`
      );
    },

    renderMarker() {
      // 已经选择的地址
      const data = this.selectMarkerList;

      const _this = this;

      let index = 0;
      const markers = data.map((item) => {
        item.index = index;
        const { latitude, longitude } = item || {};
        const marker = new AMap.Marker({
          position: [longitude, latitude],
          title: item.name,
          clickable: true,
          content: `<div class="amap_lib_placeSearch_poi yellow">${index + 1}</div>`,
          extData: item,
        });
        marker.on("click", () => {
          const data = marker.getExtData();
          this.clearLastSelectMarker(2);
          marker.setContent(`<div class="amap_lib_placeSearch_poi active">${data.index + 1}</div>`);
          _this.selectMarkerObj = marker;
        });
        index++;
        return marker;
      });

      this.selectMarkers = markers;
      this.map.add(markers);
    },

    chcekAddressInScope() {
      const scopes = this.overlays;
      const map = {};
      scopes.map((item) => {
        const id = item.getExtData().yxId;
        map[id] = item;
      });

      for (let i = 0; i < this.selectMarkerList.length; i++) {
        const item = this.selectMarkerList[i];

        const scope = map[item.belongArea];
        if (!scope) {
          item.wrong = true;
          this.$set(this.selectMarkerList, i, item);
        } else {
          const isInArea = AMap.GeometryUtil.isPointInRing(
            [item.longitude, item.latitude],
            scope.getPath()
          );
          if (!isInArea) {
            item.wrong = true;
            this.$set(this.selectMarkerList, i, item);
          }
        }

        // 特定区域还要检测在选择点在不加价区域内
        if (this.param.areaType === AREA_TYPE.SPEC_AREA_RECOMMEND_ADDRESS) {
          let belongArea = false;
          for (let index = 0; index < this.standOverlays.length; index++) {
            const element = this.standOverlays[index];
            const isInArea = AMap.GeometryUtil.isPointInRing(
              [item.longitude, item.latitude],
              element.getPath()
            );
            if (isInArea) {
              belongArea = true;
              break;
            }
          }

          if (!belongArea) {
            item.wrong = true;
            this.$set(this.selectMarkerList, i, item);
          }
        }
      }
    },

    copyArea() {
      const param = {
        pathId: this.param.pathId,
        copyPathId: this.copyParam.pathId,
        areaType: this.param.areaType,
        copyAreaType: this.copyParam.areaType,
        isStart: this.param.isStart,
        copyIsStart: this.copyParam.isStart,
      };

      copyUpriceArea(param).then(() => {
        this.$message.success("复制成功");
        this.query();
        this.visible2 = false;
      });
    },
    draw(type) {
      const mouseTool = this.mouseTool;

      const fillColor = this.isRecommendAddress ? "#f7bc64" : "#80d8ff";
      const strokeColor = this.isRecommendAddress ? "#FF9800" : "#00b0ff";
      switch (type) {
        case SHAPE.polygon: {
          mouseTool.polygon({
            fillColor,
            strokeColor,
          });
          break;
        }
        default:
          mouseTool && mouseTool.close(true);
          break;
      }
    },

    openForm(overlay) {
      this.visible = true;
      this.overlay = overlay;
      const { pathId, areaType } = this.param;

      if (!pathId || !areaType) {
        this.$message.error("请选择条件");
        return;
      }

      this.form = {
        ...this.param,
      };
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        this.visible = false;

        if (this.form.id) {
          modifyUpriceArea(this.form).then(() => {
            this.$message.success("区域修改成功");
            this.query();
          });
        } else {
          const poiDetail = MapUtil.overlay2Json(this.overlay);
          const param = {
            ...this.form,
            poiDetail,
          };
          addUpriceArea(param).then(() => {
            this.$message.success("区域保存成功");
            this.query();
          });
        }
      });
    },

    cancel() {
      this.visible = false;
      if (this.overlay) {
        this.map.remove(this.overlay);
      }
    },

    select(info) {
      this.map.clearMap();
      this.addMarker(info.poi.location, info.poi.name);
    },

    addMarker(position, title) {
      if (this.marker) {
        this.map.remove(this.marker);
      }

      const marker = new AMap.Marker({
        position,
        title,
      });

      this.marker = marker;
      this.map.add(marker);
      this.map.setFitView();
    },

    async query() {
      if (!this.param.pathId || !this.param.areaType) {
        return;
      }

      this.clearAddressSelect();
      this.clearChoose();
      this.map.clearMap();

      const areaType = this.param.areaType;
      // 单独处理查询推荐上车地址
      if (areaType === AREA_TYPE.RECOMMEND_ADDRESS) {
        this.queryRecommendAddress();
      } else if (areaType === AREA_TYPE.SPEC_AREA_RECOMMEND_ADDRESS) {
        this.querySpecRecommendAddress();
      } else if (areaType === AREA_TYPE.UPRICE) {
        const param1 = {
          ...this.param,
          areaType: AREA_TYPE.STANDARD_AREA,
        };
        const res1 = await listUpriceArea(param1);
        const list1 = res1.data || [];
        this.renderMap(list1, false, {
          strokeColor: "#409EFF",
          fillColor: "#bae7ff",
        });

        const param2 = {
          ...this.param,
          areaType: AREA_TYPE.CITY_SCOPE,
        };
        const res2 = await listUpriceArea(param2);
        const list2 = res2.data || [];
        this.renderMap(list2, false, {
          strokeColor: "#000",
          fillColor: "transparent",
        });

        const res3 = await listUpriceArea(this.param);
        this.list = res3.data || [];
        this.renderMap(this.list, true, {
          strokeColor: "#F44336",
          fillColor: "#eaa8a3",
        });
      } else if (areaType === AREA_TYPE.CITY_SCOPE) {
        const param1 = {
          ...this.param,
          areaType: AREA_TYPE.STANDARD_AREA,
        };
        const res1 = await listUpriceArea(param1);
        const list1 = res1.data || [];
        this.renderMap(list1, false, {
          strokeColor: "#409EFF",
          fillColor: "#bae7ff",
        });

        const param2 = { ...this.param, areaType: AREA_TYPE.UPRICE };
        const res2 = await listUpriceArea(param2);
        const list2 = res2.data || [];
        this.renderMap(list2, false, {
          strokeColor: "#F44336",
          fillColor: "#eaa8a3",
        });

        const res3 = await listUpriceArea(this.param);
        this.list = res3.data || [];
        this.renderMap(this.list, true, {
          strokeColor: "#000",
          fillColor: "transparent",
        });
      } else if (areaType === AREA_TYPE.STANDARD_AREA) {
        const param1 = {
          ...this.param,
          areaType: AREA_TYPE.CITY_SCOPE,
        };
        const res1 = await listUpriceArea(param1);
        const list1 = res1.data || [];
        this.renderMap(list1, false, {
          strokeColor: "#000",
          fillColor: "transparent",
        });

        const param2 = { ...this.param, areaType: AREA_TYPE.UPRICE };
        const res2 = await listUpriceArea(param2);
        const list2 = res2.data || [];
        this.renderMap(list2, false, {
          strokeColor: "#F4433600",
          fillColor: "#eaa8a3",
        });

        const res3 = await listUpriceArea(this.param);
        this.list = res3.data || [];
        this.renderMap(this.list, true, {
          strokeColor: "#409EFF",
          fillColor: "#bae7ff",
        });
      } else if (areaType === AREA_TYPE.SPEC_AREA) {
        const param1 = {
          ...this.param,
          areaType: AREA_TYPE.STANDARD_AREA,
        };
        const res1 = await listUpriceArea(param1);
        const list1 = res1.data || [];
        this.renderMap(list1, true, {
          strokeColor: "#409EFF",
          fillColor: "#bae7ff",
        });

        const param2 = {
          ...this.param,
          areaType: AREA_TYPE.CITY_SCOPE,
        };
        const res2 = await listUpriceArea(param2);
        const list2 = res2.data || [];
        this.renderMap(list2, false, {
          strokeColor: "#000",
          fillColor: "transparent",
        });

        const res = await listUpriceArea(this.param);
        this.list = res.data || [];
        this.renderMap(this.list, true, {
          strokeColor: "#F44336",
          fillColor: "#eaa8a3",
        });
      } else if (areaType === AREA_TYPE.EXPRESSWAY_AREA) {
        const param = {
          ...this.param,
          areaType: AREA_TYPE.EXPRESSWAY_AREA,
        };
        const res = await listUpriceArea(param);
        this.list = res.data || [];
        this.renderMap(this.list, true, {
          strokeColor: "#409EFF",
          fillColor: "#bae7ff",
        });
      } else if (areaType === AREA_TYPE.SPEC_AREA2) {
        const param = {
          ...this.param,
          areaType: AREA_TYPE.SPEC_AREA2,
        };
        const res = await listUpriceArea(param);
        this.list = res.data || [];
        this.renderMap(this.list, true, {
          strokeColor: "#409EFF",
          fillColor: "#bae7ff",
        });
      }
    },

    queryRecommendAddress() {
      const param = { ...this.param };
      param.areaType = AREA_TYPE.STANDARD_AREA;
      listUpriceArea(param).then((res) => {
        this.list = res.data || [];
        this.renderMap(this.list, false);
      });

      const param2 = { ...this.param };
      listUpriceArea(param2).then((res) => {
        this.selectSearchMarkerObj = null;
        this.selectMarkerObj = null;
        this.searchResult = [];
        this.selectMarkerList = res.data || [];
        this.selectedMarkerKeys = this.selectMarkerList.map(
          (item) => `${item.longitude}_${item.latitude}`
        );
        this.renderMarker();
      });
    },

    async querySpecRecommendAddress() {
      const map = this.map;
      const param1 = { ...this.param };
      param1.areaType = AREA_TYPE.STANDARD_AREA;
      const res1 = await listUpriceArea(param1);
      this.standOverlays = res1.data.map((item) => MapUtil.json2Overlay(item));

      const param2 = { ...this.param };
      param2.areaType = AREA_TYPE.SPEC_AREA;
      const res2 = await listUpriceArea(param2);
      this.overlays = res2.data.map((item) =>
        MapUtil.json2Overlay(item, {
          strokeColor: "#F44336",
          fillColor: "#eaa8a3",
        })
      );

      map.add(this.standOverlays);
      map.add(this.overlays);
      map.setFitView();

      listUpriceArea(this.param).then((res) => {
        this.selectSearchMarkerObj = null;
        this.selectMarkerObj = null;
        this.searchResult = [];
        this.selectMarkerList = res.data || [];
        this.selectedMarkerKeys = this.selectMarkerList.map(
          (item) => `${item.longitude}_${item.latitude}`
        );
        this.renderMarker();
      });
    },

    renderMap(data = [], clickable = true, colorInfo) {
      const map = this.map;
      const overlays = data.map((item) => MapUtil.json2Overlay(item, colorInfo));

      this.overlays = overlays;

      if (clickable) {
        overlays.forEach((item) => {
          item.on("click", () => {
            this.chooseOverlay(item);
          });
          item.on("mouseover", () => {
            this.priceItem = item;
          });
        });
      }

      map.add(overlays);
      map.setFitView();
    },

    updateBelong(item, index) {
      let belongArea;
      for (let index = 0; index < this.overlays.length; index++) {
        const scope = this.overlays[index];
        const isInArea = AMap.GeometryUtil.isPointInRing(
          [item.longitude, item.latitude],
          scope.getPath()
        );
        if (isInArea) {
          belongArea = scope.getExtData().yxId;
          break;
        }
      }

      if (!belongArea) {
        this.$message.error("所选的点不在不加价区域内");
        return;
      }

      item.belongArea = belongArea;
      modifyUpriceArea(item).then(() => {
        this.$message.success("地址更新成功");
        item.wrong = false;
        this.$set(this.selectMarkerList, index, item);
      });
    },

    updateOverlay() {
      if (this.polyEditor) {
        // this.polyEditor.close();
      }

      // 延迟100ms保存，等待polyEditor.close执行完成
      setTimeout(() => {
        if (this.tempOverlay) {
          const overlay = this.tempOverlay;
          const poiDetail = MapUtil.overlay2Json(overlay);
          const extData = overlay.getExtData();
          const { pathId, areaType, isStart } = this.param;
          let param = {
            id: extData.yxId,
            pathId,
            areaType,
            poiDetail,
            isStart,
          };

          if (areaType === AREA_TYPE.SPEC_AREA && this.marker) {
            const { lat, lng } = this.marker.getPosition();
            param.latitude = lat;
            param.longitude = lng;
          }
          modifyUpriceArea(param).then(() => {
            this.$message.success("区域更新成功");
            // this.query();
          });
        }
      }, 100);
    },
    saveTempOverlay(event) {
      // 存储临时修改的数据
      const overlay = event.target;
      this.tempOverlay = overlay;
    },

    chooseOverlay(item) {
      if (this.polyEditor && this.chooseItem && this.chooseItem._amap_id === item._amap_id) {
        return;
      }
      this.clearChoose();
      item.setOptions({
        strokeColor: "red",
        draggable: true,
        strokeOpacity: 1,
      });

      // 监听拖动鼠标抬起事件
      item.on("mouseup", this.saveTempOverlay);
      const polyEditor = new AMap.PolygonEditor(this.map, item);
      polyEditor.on("end", this.saveTempOverlay);
      polyEditor.on("addnode", this.saveTempOverlay);
      polyEditor.on("adjust", this.saveTempOverlay);
      polyEditor.on("removenode", this.saveTempOverlay);
      polyEditor.open();
      this.polyEditor = polyEditor;
      this.chooseItem = item;

      const itemData = item.getExtData().originData;
      const { latitude, longitude } = itemData;
      if (itemData.areaType === AREA_TYPE.SPEC_AREA) {
        // 如果已经设置了途经点，则直接展示，否则显示地点选择界面
        if (longitude && latitude) {
          this.createWayPoint([longitude, latitude]);
        } else {
          if (this.marker) {
            this.map.remove(this.marker);
          }
          this.showLocationSelectMap();
        }
      }
    },

    showLocationSelectMap(point) {
      this.$bus.$emit(SHOW_LOCATION_SELECT_MAP, point, ({ lat, lng }) => {
        this.createWayPoint([lng, lat]);
      });
    },

    createWayPoint(position) {
      if (this.marker) {
        this.map.remove(this.marker);
      }
      const marker = new AMap.Marker({
        position,
        title: "途经点",
        icon: new AMap.Icon({
          image: markB,
        }),
        anchor: "bottom-center",
        draggable: true,
        label: {
          direction: "top",
          content: "<div class='info active'>途经点</div>",
        },
      });
      marker.on("click", () => {
        this.showLocationSelectMap({
          lat: position[1],
          lng: position[0],
          name: "途经点",
        });
      });
      this.marker = marker;
      this.map.add(marker);
    },

    clearChoose() {
      if (this.chooseItem) {
        // const extData = this.chooseItem.getExtData();
        // const originData = extData.originData;
        // const opts = MapUtil.getOverlayDefaultOpts(originData);
        // this.chooseItem.setOptions(opts);
        this.chooseItem = null;
        this.tempOverlay = null;
      }
      if (this.polyEditor) {
        this.polyEditor.close();
        this.polyEditor = null;
      }
    },
    removeOverlays() {
      const extData = this.chooseItem.getExtData();
      const param = { id: extData.yxId };

      this.$confirm("确认删除该区域吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUpriceArea(param).then(() => {
          this.$message.success("区域删除成功");
          this.query();
        });
      });
    },
    editArea() {
      const extData = this.chooseItem.getExtData();
      const id = extData.yxId;
      const item = this.list.find((item) => item.id === id);
      if (item) {
        this.form = _.cloneDeep(item);
        this.visible = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
#up_price_map {
  height: 100%;
}

.up_price_map {
  width: 100%;
  height: 750px;
  position: relative;
  overflow: hidden;

  .input-card {
    padding: 20px;
    position: absolute;
    background-color: #ffffff;
    right: 5px;
    bottom: 5px;
    font-size: 14px;
    border-radius: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  }

  // .uprice-info {
  //   padding: 10px;
  //   position: absolute;
  //   background-color: #ffffff;
  //   right: 5px;
  //   width: 150px;
  //   top: 5px;
  //   font-size: 14px;
  //   border-radius: 0.4rem;
  //   box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  // }

  .uprice-info {
    padding: 10px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: wheat;
    right: 0;
    left: 0;
    margin: auto;
    width: 150px;
    top: 5px;
    font-size: 14px;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  }
}

#tipinput {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border: none;
  outline: none;
}

.query-container {
  height: 40px;
  padding: 10px 20px;
}

.result {
  margin: 0;
  padding: 0;
  z-index: 999;
  position: absolute;
  background-color: white;
  height: 80%;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 295px;
  padding: 10px;
  box-shadow: 0px 3px 5px #333333;
  border-radius: 10px;

  &.left {
    left: 0;
    right: auto;
  }

  .marker-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    margin-bottom: 10px;

    &.disable {
      filter: grayscale(100%);
    }

    .name {
      font-size: 14px;
      display: flex;
      width: 220px;
      align-items: center;
      .icon {
        background: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png) no-repeat;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding-top: 3px;
        width: 20px;
        height: 26px;
        background-size: cover;
        margin-right: 5px;
        display: inline-block;
        // transform: scale(0.9);
        &.active {
          background-image: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png);
        }
      }
    }

    .btn {
      margin-left: 10px;
      color: #4894ef;
      font-size: 14px;

      width: 40px;
    }
  }
}
</style>
