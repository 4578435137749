<template>
  <div>
    <el-dialog
      :visible.sync="punishVisible"
      width="1100px"
      :close-on-click-modal="false"
      @closed="clearData"
    >
      <el-container>
        <el-main class="border" style="border-right: none">
          <el-header>
            <div class="row title">开罚单</div>
          </el-header>
          <el-form>
            <div class="row flex">
              <el-input
                class="input-item violateCommonElement"
                v-model="punishBill.driverNo"
                placeholder="请输入司机编号"
                @change="(val) => getDriverBaseInfo(val, true)"
              ></el-input>
              <span v-if="driverInfo.id"
                >{{ driverInfo.name }} {{ driverInfo.no }} {{ driverInfo.seats + "座" }}
                {{ driverInfo.vehicleColor }} ● {{ driverInfo.brand }}
              </span>

              <span v-if="driverInfo.newer" class="newer" @click="addNewerText">新手司机</span>
            </div>

            <div class="row flex">
              <el-date-picker
                class="violateCommonElement"
                v-model="punishBill.violateDate"
                type="date"
                @change="onDatePathChange(true)"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="违规日期"
              >
              </el-date-picker>

              <el-select
                class="violateCommonElement"
                v-model="punishBill.pathId"
                clearable
                placeholder="违规路线"
                @change="onDatePathChange(true)"
              >
                <el-option
                  v-for="item in pathOpts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-select
                class="violateCommonElement"
                v-model="punishBill.routeId"
                clearable
                placeholder="违规行程"
                @change="getQcResources"
              >
                <el-option
                  v-for="item in routeOpts"
                  :key="item.id"
                  :label="item.showTime"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-select
                class="violateCommonElement"
                style="width: 240px"
                v-model="punishBill.orderId"
                clearable
                placeholder="违规订单"
                filterable
                :remote="orderOpts.length === 0"
                :remote-method="queryOrders"
              >
                <el-option
                  v-for="item in orderOpts"
                  :key="item.id"
                  :label="item.orderIndex"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="row flex">
              <el-select
                class="violateCommonElement"
                v-model="punishBill.violateSrc"
                clearable
                placeholder="违规来源"
              >
                <el-option
                  v-for="item in violateSrcOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-select
                class="violateCommonElement"
                @change="evidenceChange"
                v-model="punishBill.evidence"
                clearable
                placeholder="有无违规证据"
              >
                <el-option
                  v-for="item in evidenceOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <div v-if="punishBill.evidence && !viewMode" class="violateCommonElement">
                <file-upload
                  ref="uploader"
                  :onUploadSuccess="
                    (src) => {
                      addAnnex(src);
                    }
                  "
                  filePath="/driver_puinsh"
                  :showFileList="false"
                >
                  <i class="el-icon-plus"></i>
                </file-upload>
              </div>
            </div>

            <div class="row">
              <el-select
                class="violateCommonElement"
                v-model="punishBill.violateClassify1"
                @change="(val) => onViolateClassify1Change(val, true)"
                filterable
                clearable
                placeholder="违规大类"
              >
                <el-option
                  v-for="item in violateClassify1Opts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-select
                class="violateCommonElement"
                style="width: 220px"
                v-model="punishBill.violateClassify2"
                filterable
                clearable
                @change="onViolateClassify2Change"
                placeholder="违规小类"
              >
                <el-option
                  v-for="item in filterClassify2Opts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <span
                v-if="punishBill.violateClassify2 && punishRelation"
                style="display: inline-block"
                class="violateCommonElement"
                >文档:
                <span v-for="item in punishRelation.docCodes" :key="item" style="margin-right: 4px">
                  <el-link type="primary" @click="openTrainingDocx(item)">{{ item }}</el-link>
                </span></span
              >

              <span v-if="punishBill.violateClassify2 && punishRelation"
                >题库:

                <span
                  v-for="item in punishRelation.questionCodes"
                  :key="item"
                  style="margin-right: 4px"
                >
                  <el-link type="primary" @click="openExams(item)">{{ item }}</el-link>
                </span>
              </span>
            </div>

            <div class="row flex">
              <span> 处罚结果：</span>
              <span v-if="punishRelation">
                <el-checkbox-group v-model="punishBill.punishResult">
                  <el-checkbox
                    :label="item"
                    v-for="item in punishRelation.punishOpts"
                    :key="item"
                  ></el-checkbox>
                </el-checkbox-group>
              </span>
              <span
                v-if="
                  punishRelation &&
                  punishBill.punishResult &&
                  punishBill.punishResult.includes('罚款')
                "
                ><el-input
                  size="small"
                  v-model="punishBill.amount"
                  style="width: 60px; margin: 0 6px 0 20px"
                ></el-input
                >元
                <span v-if="punishRelation.minPunishAmount && punishRelation.maxPunishAmount"
                  >（{{ punishRelation.minPunishAmount }} -
                  {{ punishRelation.maxPunishAmount }}元之间）</span
                >
              </span>
              <span v-if="punishBill.ljBillCnt > 0">{{
                "（已累计" + punishBill.ljBillCnt + "张同类型罚单）"
              }}</span>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div style="margin-bottom: 4px">处罚说明：</div>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="处罚说明"
                v-model="punishBill.remark"
              >
              </el-input>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div style="margin-bottom: 4px">备注：</div>
              <el-input
                type="textarea"
                :rows="1"
                placeholder="备注内容，司机不可见"
                v-model="punishBill.bz"
              >
              </el-input>
            </div>

            <div class="row reverse">
              <el-checkbox
                :value="punishBill.sendType === '1'"
                @change="sendTypeChange"
                style="margin-right: 10px; color: red"
                >行程结束后发送</el-checkbox
              >

              <el-button size="small" @click="punishVisible = false">取消</el-button>
              <!-- <el-button v-if="!viewMode" @click="savePunishBillData('1')">暂存</el-button> -->
              <el-button
                size="small"
                v-if="!viewMode"
                type="primary"
                plain
                @click="savePunishBillData('2')"
                >保存</el-button
              >
              <el-button size="small" v-if="!viewMode" type="primary" plain @click="doSend()"
                >保存并发送罚单</el-button
              >
            </div>
          </el-form>
        </el-main>
        <el-aside class="violate-preview-container">
          <div>
            <div class="note">
              <div class="title">注意事项</div>
              <div>1. 开单注意事项说明</div>
              <div>2. 开单注意事项说明</div>
              <div>3. 开单注意事项说明</div>
              <div>4. 开单注意事项说明</div>
            </div>

            <div class="media-box" v-if="qcResources">
              <div>附件证据选择：</div>
              <el-checkbox-group v-model="punishBill.annexes">
                <el-checkbox v-for="item in qcResources" :key="item.id" :label="item.url">{{
                  item.remark || item.name
                }}</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="media-box">
              <div v-for="annex in punishBill.annexes" :key="annex" class="media-item">
                <div class="box">
                  <el-image
                    v-if="isImage(annex)"
                    style="width: 100%; height: 40px"
                    :src="annex"
                    :preview-src-list="[annex]"
                  >
                  </el-image>
                  <audio
                    v-if="isAudio(annex)"
                    controls
                    style="width: 100%; height: 40px"
                    :src="annex"
                  ></audio>
                </div>
                <div class="icon">
                  <i class="el-icon-circle-close icon-del" @click="removeAnnex(annex)"></i>
                </div>
              </div>
            </div>
          </div>
        </el-aside>
      </el-container>
    </el-dialog>

    <el-dialog :visible.sync="showDocxPannel" width="1100px">
      <div v-if="doc">
        <div style="text-align: center; font-size: 18px; font-weight: bold; margin-bottom: 10px">
          {{ doc.seq }}. {{ doc.title }}
        </div>
        <div style="line-height: 1.6" v-html="doc.content"></div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showExamPannel" width="628px">
      <div v-if="exam">
        <ItemView :paperItm="exam" :sno="1" :preview="true"></ItemView>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDriverInfo,
  getDoc,
  getExamItems,
  getPunishBillNum,
  findViolate,
  savePunishBill,
  getRouteList,
  getOrderList,
  getOrder,
  sendPunish,
  getRouteResourceList,
} from "@/api";
import { mapState } from "vuex";
import ItemView from "../../pages/Exam/admin/editor/ItemView";

export default {
  components: {
    ItemView,
  },

  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      teams: (state) => state.teamOpts,
      pathOpts: (state) => state.pathList,
      violateSrcOpts: (state) => state.enumMap["EVA_SOURCE_CHANNEL"],
      violateClassify1Opts: (state) => state.enumMap["DRIVER_VIOLATE_TYPE"],
      violateClassify2Opts: (state) => state.enumMap["DRIVER_VIOLATE_OPT_TYPE"],
    }),
  },

  data() {
    return {
      punishVisible: false,
      punishBill: {},
      driverInfo: {},
      evidenceOpts: [
        { value: true, label: "有" },
        { value: false, label: "无" },
      ],
      filterClassify2Opts: [],
      routeOpts: [],
      orderOpts: [],
      exams: [],
      docxContent: [],
      punishRelation: null,
      showDocxPannel: false,
      showExamPannel: false,
      exam: null,
      doc: null,
      viewMode: false,
      qcResources: null,
    };
  },

  methods: {
    show(data) {
      const { order, route, punishBill, qc, callback, viewMode, orderList = [] } = data || {};
      this.callback = callback;
      this.viewMode = viewMode;
      if (route) {
        const { driverNo, driverId, teamId, pathId, id: routeId, date, showTime } = route;
        const { qcResources, qcSetting } = qc || {};
        const { violateClassify1, violateClassify2 } = qcSetting || {};
        const { id: orderId, orderIndex, departure } = order || {};
        this.getDriverBaseInfo(driverNo, false);
        this.routeOpts = [{ showTime, id: routeId }];

        this.orderOpts = orderList.map((item) => {
          return {
            orderIndex: `${item.orderIndex + 1}号单-${item.departure}`,
            id: item.id,
          };
        });

        if (orderId && !orderList.length) {
          this.orderOpts = [{ orderIndex: `${orderIndex + 1}号单-${departure}`, id: orderId }];
        }

        let violateSrc = null;
        let evidence = null;

        if (qcResources && qcResources.length > 0) {
          this.qcResources = qcResources;
        } else {
          this.getQcResources(routeId);
        }

        if (violateClassify1) {
          // 将司机id信息保存起来，onViolateClassify2Change会用到
          this.driverInfo = { id: driverId, driverNo };
          // 主动触发查询出发结果
          this.onViolateClassify1Change(violateClassify1, false);
          this.onViolateClassify2Change(violateClassify2);
          // 客服质检
          violateSrc = 3;
          evidence = true;
        }
        this.punishBill = {
          driverId,
          driverNo,
          teamId,
          violateDate: date,
          violateTime: null,
          pathId,
          routeId,
          orderId: orderId,
          violateSrc,
          violateClassify1,
          violateClassify2,
          evidence,
          annexes: [],
          punishResult: [],
          remark: null,
          bz: null,
        };
      } else if (order) {
        const { id: orderId, orderIndex, pathId, date, departure } = order || {};
        this.orderOpts = [{ orderIndex: `${orderIndex + 1}号单-${departure}`, id: orderId }];
        this.punishBill = {
          violateDate: date,
          violateTime: null,
          pathId,
          orderId: orderId,
          violateSrc: null,
          violateClassify1: null,
          violateClassify2: null,
          evidence: null,
          annexes: [],
          punishResult: [],
          remark: null,
          bz: null,
        };
      } else if (punishBill) {
        if (!punishBill.punishResult) {
          punishBill.punishResult = [];
        }

        this.punishBill = punishBill;

        const {
          violateClassify1,
          violateClassify2,
          driverNo,
          pathId,
          driverId,
          violateDate,
          orderId,
        } = punishBill;
        // 将司机id信息保存起来，onViolateClassify2Change会用到
        this.driverInfo = { id: driverId, driverNo };

        if (violateClassify1) {
          this.onViolateClassify1Change(violateClassify1, false);
        }

        if (pathId && driverId && violateDate) {
          const param = { pathId, date: violateDate, driverId };
          getRouteList(param).then((res) => {
            this.routeOpts = res.data || [];
          });
        }

        if (orderId) {
          getOrder({ id: orderId }).then((res) => {
            const { id: orderId, orderIndex, departure } = res.data || {};
            this.orderOpts = [{ orderIndex: `${orderIndex + 1}号单-${departure}`, id: orderId }];
          });
        }

        if (violateClassify2) {
          this.onViolateClassify2Change(violateClassify2);
        }

        if (driverNo) {
          this.getDriverBaseInfo(driverNo, false);
        }
      }

      this.punishVisible = true;
    },

    getQcResources(routeId) {
      getRouteResourceList(routeId).then((res) => {
        if (res.data && res.data.length) {
          this.qcResources = res.data;
        } else {
          this.qcResources = null;
        }
      });
    },

    sendTypeChange(val) {
      // 1: 行程结束后发送 2: 立即发送
      this.$set(this.punishBill, "sendType", val ? "1" : "2");
    },

    clearData() {
      this.punishBill = {};
      this.driverInfo = {};
      this.routeOpts = [];
      this.orderOpts = [];
      this.exams = [];
      this.docxContent = [];
      this.punishRelation = null;
      this.showDocxPannel = false;
      this.showExamPannel = false;
    },

    doSend() {
      // 状态改为已保存
      this.punishBill.state = "2";

      savePunishBill(this.punishBill).then((res) => {
        const { id, state, sendType } = res.data;
        this.$set(this.punishBill, "id", id);
        this.$set(this.punishBill, "state", state);
        // 立即发送才需要调用发送接口
        if (sendType === "2") {
          sendPunish({ id: this.punishBill.id }).then(() => {
            this.callback && this.callback(id);
            this.punishVisible = false;
            this.$message.success("保存成功");
          });
        } else {
          this.punishVisible = false;
          this.$message.success("保存成功");
        }
      });
    },

    getDriverBaseInfo(driverNo, resetData) {
      getDriverInfo({ no: driverNo, status: 1 }).then((res) => {
        this.driverInfo = res.data || {};
        const { id: driverId, teamId, no: driverNo } = this.driverInfo;
        if (resetData) {
          this.routeOpts = [];
          this.orderOpts = [];
          this.punishBill = {
            driverId,
            driverNo,
            teamId,
            orderId: null,
          };
        }
      });
    },

    onDatePathChange(resetData) {
      const { violateDate, pathId } = this.punishBill;
      if ((this.driverInfo.id && violateDate, pathId)) {
        const param = { pathId, date: violateDate, driverId: this.driverInfo.id };
        this.routeOpts = [];
        if (resetData) {
          this.$set(this.punishBill, "routeId", null);
        }
        getRouteList(param).then((res) => {
          this.routeOpts = res.data || [];
          if (this.routeOpts.length === 1 && resetData) {
            this.$set(this.punishBill, "routeId", this.routeOpts[0].value);
          }
        });
      }
    },

    queryOrders(telephone) {
      const { violateDate, pathId } = this.punishBill;
      if (violateDate && pathId && telephone.length === 11) {
        this.$set(this.punishBill, "orderId", null);
        const param = { pathId, date: violateDate, telephone };
        const query = {
          page: {
            current: 1,
            size: 100,
          },
          param,
        };
        getOrderList(query).then((res) => {
          this.orderOpts = (res.data.records || []).map((item) => {
            return {
              orderIndex: `${item.orderIndex + 1}号单-${item.departure}`,
              id: item.id,
            };
          });

          if (this.orderOpts.length === 1) {
            this.$set(this.punishBill, "orderId", this.orderOpts[0].id);
          }
        });
      } else if (telephone.length === 11) {
        this.$message.error("请先选择违规日期和线路");
      }
    },

    evidenceChange(val) {
      if (!val) {
        this.punishBill.annexes = [];
      }
    },

    openTrainingDocx(code) {
      this.showDocxPannel = true;
      this.doc = null;
      getDoc(code).then((res) => {
        this.doc = res.data;
      });
    },

    openExams(code) {
      this.showExamPannel = true;
      this.exam = null;
      getExamItems(code).then((res) => {
        this.exam = res.data;
      });
    },

    onViolateClassify1Change(val, resetData) {
      this.exams = [];
      this.docxContent = [];
      if (resetData) {
        this.$set(this.punishBill, "violateClassify2", null);
      }

      this.filterClassify2Opts = [];
      const list = [];
      this.violateClassify2Opts.forEach((opt) => {
        if (String(opt.value).indexOf(val + "-") == 0) {
          list.push(opt);
        }
      });
      this.filterClassify2Opts = list;
    },

    onViolateClassify2Change(val) {
      this.exams = [];
      this.docxContent = [];

      if (val) {
        const param = { violateCode1: this.punishBill.violateClassify1, violateCode2: val };

        findViolate(param).then((res) => {
          this.punishRelation = res.data;
          const {
            violateMark,
            defaultPunishes = [],
            accumulate,
            punishScore,
            punishAmount,
          } = this.punishRelation;
          const { punishResult = [] } = this.punishBill;
          // 只有编辑模式，且punishResult为空才需要设置默认值
          if (!this.viewMode && punishResult.length === 0) {
            this.$set(this.punishBill, "score", punishScore);
            this.$set(this.punishBill, "amount", punishAmount);
            this.$set(this.punishBill, "remark", violateMark);
            this.$set(this.punishBill, "punishResult", defaultPunishes);
          }

          // 是否累计
          if (accumulate) {
            const params = {
              violateCode1: this.punishBill.violateClassify1,
              violateCode2: val,
              driverId: this.driverInfo.id,
            };
            getPunishBillNum(params).then((res2) => {
              this.$set(this.punishBill, "ljBillCnt", res2.data || 0);
            });
          } else {
            this.$set(this.punishBill, "ljBillCnt", 0);
          }
        });
      }
    },

    savePunishBillData(state) {
      if (this.punishBill.state == "2" && state == "1") {
        this.$message.error("罚单已保存过，不可再暂存");
        return;
      }
      this.punishBill.state = state;
      this.punishBill.teamId = this.driverInfo.team;
      this.punishBill.seat = this.driverInfo.seats;
      // const  added = !this.punishBill.id;
      savePunishBill(this.punishBill).then((res) => {
        const { id, state } = res.data;
        this.$set(this.punishBill, "id", id);
        this.$set(this.punishBill, "state", state);
        this.punishVisible = false;
        this.$message.success("保存成功");
        this.callback && this.callback(id);
      });
    },

    addAnnex(src) {
      const annexes = this.punishBill.annexes || [];
      annexes.push(src);
      this.$set(this.punishBill, "annexes", annexes);
      this.$refs.uploader.clear();
    },

    removeAnnex(src) {
      const annexes = this.punishBill.annexes || [];
      for (var i = 0; i < annexes.length; i++) {
        if (src === annexes[i]) {
          annexes.splice(i, 1);
          break;
        }
      }
      this.$set(this.punishBill, "annexes", annexes);
    },

    isImage(src) {
      return /(.jpg|.jfif|.jpeg|.png|.bmp|.gif)$/i.test(src);
    },
    isVedio(src) {
      return /(.mp4|.m3u8|.3gp)$/i.test(src);
    },
    isAudio(src) {
      return /(.mp3|.wav)$/i.test(src);
    },

    addNewerText() {
      const text = this.punishBill.remark || "";
      this.$set(
        this.punishBill,
        "remark",
        text +
          "（您当前属于签约后的15天新手保护期，本次违规行为不扣款，仅扣服务分，以示提醒！同时温馨提示您后期注意规避此类行为）"
      );
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-bottom: 10px;

  .el-main {
    padding: 4px 10px;
  }
}

/deep/ .el-textarea__inner {
  padding: 6px;
}

/deep/ .el-upload-dragger {
  width: 40px;
  height: 40px;

  .el-icon-plus {
    margin-top: 12px;
  }
}

/deep/ .upload-box {
  display: inline-block !important;
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.row {
  margin: 0 0 10px 0;
  min-height: 50px;

  &.reverse {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    min-height: 0;
    align-items: center;
  }

  &.flex {
    display: flex;
    align-items: center;
  }
}

.media-box {
  margin-top: 40px;

  .media-item {
    display: flex;
    margin-bottom: 10px;

    .box {
      flex: 1;
      overflow: hidden;
    }

    .icon {
      width: 40px;
      margin-left: 10px;
      font-size: 30px;
    }

    .img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}

.violateCommonElement {
  position: relative;
  display: inline-block;
  width: 160px;
  margin-right: 10px;
}

.violate-preview-container {
  margin-left: 20px;
  width: 300px;
}

.newer {
  color: red;
  text-decoration: underline;
  margin-left: 30px;
  cursor: pointer;
}
</style>
