<template>
    <el-upload class="upload-box"
               drag
               :http-request="uploadHandle"
               :limit="1"
               action=""
               :on-remove="clear"
               :show-file-list="showFileList"
               ref="uploader">
      <div v-loading="loading">
        <slot>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </slot>
      </div>
    </el-upload>
</template>

<script>
import OSS from "ali-oss";
import { getOssUploadRole } from "@/api";
import { Toolkit } from "@/util";

export default {
  components: {},
  props: {
    onUploadSuccess: Function,
    value: String,
    bucket: {
      type: String,
      default: "yxmgt-private",
    },
    filePath: {
      type: String,
      default: "/driver/contract",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      handler (val) {
        this.src = val;

        if (!val) {
          this.clear();
        }
      },
      immediate: true,
    },
  },
  computed: {},
  data () {
    return {
      src: "",
      stsCredentials: "",
      loading: false,
    };
  },
  methods: {
    clear () {
      this.$refs.uploader && this.$refs.uploader.clearFiles();
      this.$emit("input", null);
    },

    async uploadHandle (option) {
      const client = await this.getOssClient();
      const fileType = option.file.name.split(".")[1];
      const fullName = `${this.filePath}/${Toolkit.uuid()}.${fileType}`;
      this.loading = true;
      client
        .put(fullName, option.file)
        .then((res) => {
          this.loading = false;
          this.src = res.url;
          this.$emit("input", this.src);
          if (this.onUploadSuccess) {
            this.onUploadSuccess(this.src);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$message.error("上传失败");
          this.clear();
        });
    },
    async getOssClient () {
      if (this.stsCredentials) {
        const {
          accessKeyId,
          accessKeySecret,
          expiration,
          securityToken,
        } = this.stsCredentials || {};
        const expireDate = new Date(expiration);
        // 如果过期时间大于3分钟，则无需替换凭证
        if (
          expireDate.getTime() - new Date().getTime() >
                    3 * 60 * 1000
        ) {
          return new OSS({
            accessKeyId,
            accessKeySecret,
            stsToken: securityToken,
            bucket: this.bucket,
          });
        }
      }

      const res = await getOssUploadRole();
      this.stsCredentials = res.data.credentials;
      const { accessKeyId, accessKeySecret, securityToken } =
                this.stsCredentials;
      return new OSS({
        accessKeyId,
        accessKeySecret,
        stsToken: securityToken,
        bucket: this.bucket,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.upload-box{
    display: block;
}
</style>