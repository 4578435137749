<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true" :model="param">
        <el-form-item label="手机号：" prop="telephone">
          <el-input v-model="param.telephone" style="width: 200px" clearable />
        </el-form-item>

        <el-form-item label="司机编号：" prop="no">
          <el-input v-model="param.no" style="width: 200px" clearable />
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100px; margin-right: 20px"
            type="primary"
            @click="query()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <div v-if="driverDetail">
      <el-form
        ref="form"
        :model="driverDetail"
        label-width="80px"
        size="small"
        inline
        :disabled="true"
        label-position="top"
      >
        <el-collapse accordion value="tab1">
          <el-collapse-item name="tab1">
            <template slot="title">
              <el-divider content-position="left">
                基本信息 <i class="iconfont">&#xe6ca;</i>
              </el-divider>
            </template>
            <div class="card">
              <el-row>
                <el-col :span="4">
                  <el-form-item label="姓名：" prop="driverNo">
                    <el-input v-model="driver.name" placeholder></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="所属车队：" prop="team">
                    <el-select v-model="driver.teamId" placeholder="请选择">
                      <el-option
                        v-for="item in teamOpts"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="司机编号：" prop="driverNo">
                    <el-input v-model="driver.no" placeholder></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="当前提点：" prop="registerTime">
                    <el-input
                      v-model="driver.commission"
                      placeholder
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="车辆类型" prop="vehicleTypeId">
                    <el-select
                      v-model="driver.vehicleTypeId"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in vehicleTypeOpts"
                        :key="item.id"
                        :label="item.content"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="注册时间：" prop="registerTime">
                    <el-input
                      v-model="driver.registerTime"
                      placeholder
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <el-divider content-position="left"
                >身份证信息 <i class="iconfont">&#xe73d;</i></el-divider
              >
            </template>
            <div class="card">
              <el-row>
                <el-col :span="4">
                  <el-form-item label="身份证正面：" prop="cardPosUrl">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.cardPosUrl"
                      :preview-src-list="[driverDetail.cardPosUrl]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="身份证背面：" prop="cardNegUrl">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.cardNegUrl"
                      :preview-src-list="[driverDetail.cardNegUrl]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="手持身份证：" prop="cardPersonUrl">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.cardPersonUrl"
                      :preview-src-list="[driverDetail.cardPersonUrl]"
                    ></el-image>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="一寸登记照：" prop="driverPhotoUrl">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.driverPhotoUrl"
                      :preview-src-list="[driverDetail.driverPhotoUrl]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="无犯罪证明：" prop="noCrimePic">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.noCrimePic"
                      :preview-src-list="[driverDetail.noCrimePic]"
                    ></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="手机号码：">
                    <el-input
                      v-model="driverDetail.telephone"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="姓名：">
                    <el-input v-model="driverDetail.name" disabled></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="身份证号：">
                    <el-input v-model="driverDetail.cardNo" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="民族：">
                    <el-input v-model="driverDetail.nation" disabled></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="户口登记机关：">
                    <el-input v-model="driverDetail.census" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="户口住址：">
                    <el-input
                      v-model="driverDetail.address"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-divider content-position="left">其他信息</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="紧急联系人：">
                    <el-input
                      v-model="driverDetail.emergencyContact"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="紧急联系人电话：">
                    <el-input
                      v-model="driverDetail.emergencyContactPhone"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="与紧急联系人关系：">
                    <el-input
                      v-model="driverDetail.emergencyContactRelationship"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="婚姻状况：">
                    <el-input
                      v-model="driverDetail.maritalStatus"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="外语能力：">
                    <el-select
                      v-model="driverDetail.languageLevel"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in languageLevelOpts"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="学历：">
                    <el-select
                      v-model="driverDetail.education"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in educationOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <el-divider content-position="left"
                >驾照信息 <i class="iconfont">&#xe7bf;</i></el-divider
              >
            </template>
            <div class="card">
              <el-row>
                <el-col :span="4">
                  <el-form-item label="驾照正本：">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.driverLicensePic1"
                      :preview-src-list="[driverDetail.driverLicensePic1]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="驾驶证正副本合照：">
                    <el-image
                      :fit="fit"
                      :src="driverDetail.driverLicensePic2"
                      :preview-src-list="[driverDetail.driverLicensePic2]"
                    ></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="驾驶证号：">
                    <el-input
                      v-model="driverDetail.licenseId"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="准驾车型：">
                    <el-input
                      v-model="driverDetail.driverType"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="初次领证日期：">
                    <el-input
                      v-model="driverDetail.getDriverLicenseDate"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="驾驶证有效期起：">
                    <el-input
                      v-model="driverDetail.driverLicenseOn"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="驾驶证有效期止：">
                    <el-input
                      v-model="driverDetail.driverLicenseOff"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
            </div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <el-divider content-position="left"
                >车辆基本信息 <i class="iconfont">&#xe602;</i></el-divider
              >
            </template>
            <div class="card" v-if="vehicle">
              <el-row>
                <el-col :span="4">
                  <el-form-item label="行驶证正本：">
                    <el-image
                      :fit="fit"
                      :src="vehicle.drivingLicensePic1"
                      :preview-src-list="[vehicle.drivingLicensePic1]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="行驶证正副本合照：">
                    <el-image
                      :fit="fit"
                      :src="vehicle.drivingLicensePic2"
                      :preview-src-list="[vehicle.drivingLicensePic2]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆左前方照片：">
                    <el-image
                      :fit="fit"
                      :src="vehicle.carPic1"
                      :preview-src-list="[vehicle.carPic1]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆侧面照片：">
                    <el-image
                      :fit="fit"
                      :src="vehicle.carPic2"
                      :preview-src-list="[vehicle.carPic2]"
                    ></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆正后方照片：">
                    <el-image
                      :fit="fit"
                      :src="vehicle.carPic3"
                      :preview-src-list="[vehicle.carPic3]"
                    ></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="车牌号：">
                    <el-input v-model="vehicle.vehicleNo" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆所有人：">
                    <el-input v-model="vehicle.ownerName" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆厂牌：">
                    <el-input v-model="vehicle.brand" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆型号：">
                    <el-input v-model="vehicle.model" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车身颜色：">
                    <el-input v-model="vehicle.vehicleColor"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="核定载客位（含司机）" prop="seats">
                    <el-select v-model="vehicle.seats" placeholder="请选择">
                      <el-option
                        v-for="item in seatsOpts"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="4">
                  <el-form-item label="发动机号：">
                    <el-input v-model="vehicle.engineId" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆VIN码：">
                    <el-input v-model="vehicle.vin" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆注册日期：">
                    <el-input
                      v-model="vehicle.certifyDateA"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆燃料类型：" prop="fuelType">
                    <el-select v-model="vehicle.fuelType" placeholder="请选择">
                      <el-option
                        v-for="item in fuelTypeOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="发动机排量：" prop="engineDisplace">
                    <el-select
                      v-model="vehicle.engineDisplace"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in engineDisplaceOpts"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="下次年检日期：" prop="nextFixDate">
                    <el-date-picker
                      placeholder="选择时间"
                      v-model="vehicle.nextFixDate"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-form-item label="行驶里程（公里）：" prop="totalMile">
                  <el-input-number
                    style="width: 200px"
                    v-model="vehicle.totalMile"
                  ></el-input-number>
                </el-form-item>
              </el-row>

              <el-divider content-position="left">GPS信息</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="物联网卡号：" prop="feePrintId">
                    <el-input v-model="vehicle.iotCardNo"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="卫星定位装置品牌：" prop="gpsBrand">
                    <el-input v-model="vehicle.gpsBrand"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="卫星定位装置型号：" prop="gpsModel">
                    <el-input v-model="vehicle.gpsModel"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="卫星定位装置IMEI号：" prop="gpsImei">
                    <el-input v-model="driver.gpsImei"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="安装日期：">
                    <el-date-picker
                      placeholder="选择时间"
                      value-format="yyyy-MM-dd"
                      v-model="vehicle.gpsInstallDate"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title">
              <el-divider content-position="left"
                >合同费用信息 <i class="iconfont">&#xe605;</i></el-divider
              >
            </template>
            <div v-if="driverContract" class="card">
              <el-row>
                <el-col :span="4">
                  <el-form-item label="提成比例：" prop="commission">
                    <el-input-number
                      v-model="driverContract.commission"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    label="月度管理费："
                    prop="monthlyManagementFees"
                  >
                    <el-input-number
                      v-model="driverContract.monthlyManagementFees"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    label="年度管理费："
                    prop="annualManagementFees"
                  >
                    <el-input-number
                      v-model="driverContract.annualManagementFees"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="押金：" prop="deposit">
                    <el-input-number
                      v-model="driverContract.deposit"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="合同有效期起：">
                    <el-input v-model="driverContract.contractOn"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="合同有效期止：">
                    <el-input v-model="driverContract.contractOff"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-form-item label="电子协议：">
                  <el-link
                    type="primary"
                    :href="
                      driverContract.pdfOfContact || driverContract.picture1
                    "
                    target="_blank"
                    >电子合同</el-link
                  >

                  &nbsp;&nbsp;&nbsp;

                  <el-link
                    type="primary"
                    :href="
                      driverContract.pdfOfSafeDrive || driverContract.picture2
                    "
                    target="_blank"
                    >安全驾驶承诺书</el-link
                  >

                  &nbsp;&nbsp;&nbsp;

                  <el-link
                    type="primary"
                    :href="driverContract.pdfOfRiskNotification"
                    target="_blank"
                    >风险告知</el-link
                  >
                  &nbsp;&nbsp;&nbsp;

                  <el-link
                    type="primary"
                    :href="driverContract.pdfOfFareCollection"
                    target="_blank"
                    >车费代收委托书</el-link
                  >
                </el-form-item>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getDriverDetail} from '@/api';
import { mapState } from 'vuex';
import { ParamUtil } from '@/util';


export default {
  created () {
    const id = this.$route.query.id;
    const telephone = this.$route.query.tel;
    if (id) {
      this.param = {
        id
      };
      this.query();
    } else if (telephone) {
      this.param = {
        telephone
      };
      this.query();
    }
  },

  computed: {
    ...mapState({
      size: state => state.btnSize,
      driverStatusOpts: state => state.enumMap['DRIVER_STATUS'],
      teamOpts: state => state.teamOpts,
      vehicleTypeOpts: state => state.vehicleTypeOpts,
    })
  },
  data () {
    return {
      fit: 'contain',
      visible: false,
      param: {},
      driver: null,
      driverDetail: null,
      driverContract: null,
      vehicle: null,
      engineDisplaceOpts: ['1498', '1599', '1798', '1998', '2384'],
      languageLevelOpts: ['精通', '熟练', '良好', '一般'],
      seatsOpts: [5, 7],
      fuelTypeOpts: [{
        value: 'A',
        label: '汽油'
      }, {
        value: 'B',
        label: '柴油'
      }, {
        value: 'C',
        label: '电(以电能驱动的汽车)'
      }, {
        value: 'D',
        label: '混合油'
      }, {
        value: 'E',
        label: '天然气'
      }, {
        value: 'F',
        label: '液化石油气'
      }],
      educationOpts: [{
        value: '19',
        label: '研究生'
      }, {
        value: '21',
        label: '大学本科'
      }, {
        value: '31',
        label: '大学专科'
      }, {
        value: '41',
        label: '中等专科毕业'
      }, {
        value: '61',
        label: '普通高中毕业'
      }, {
        value: '71',
        label: '初中毕业'
      }, {
        value: '81',
        label: '小学毕业'
      }],
    };
  },
  methods: {

    query () {
      const param = ParamUtil.filterEmptyData(this.param);
      getDriverDetail(param).then(res=>{
        const {driver, driverDetail, driverContract, vehicle} = res.data || {};
        this.driver = driver;
        this.driverDetail = driverDetail;
        this.driverContract = driverContract;
        this.vehicle = vehicle;
      });
    }

  }
};
</script>

<style scoped lang="less">
.root-box .el-image {
  width: 200px;
  height: 120px;
}

.card {
  margin: 0 20px 0 40px;
}

.el-divider .is-left {
  // color: #4894ef;
  color: gray;
  .iconfont {
    float: left;
    margin-right: 10px;
    font-size: 20px;

    // color: gray;
  }
}

/deep/ .el-collapse-item__header,
.el-collapse {
  border: none !important;
}

/deep/ .el-collapse-item__wrap {
  border: none !important;
}

/deep/ .el-collapse-item__content {
  padding: 10px 0;
}
</style>
